.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: #92004D ;
    border-color: #92004D ;
    color: #fff !important;
  }
  .pagination > li > a{
    border: 1px solid #92004D;
    padding: 2px 8px;
    outline: none;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 5px;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #92004D ;
    border-color: #92004D;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #92004D
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset;
    border-radius: 5px;
  }