.navbar {
  background-color: #fff;
  box-shadow: 0px 2px 10px #cfd2da;
}
.navbar .buttons button,
.navbar .buttons .notificaciones {
  padding: 0;
  width: 32px;
  height: 30px;
  position: relative;
  border: 1px solid #b0b0b0;
  color: gray;
}
.navbar .buttons button:hover,
.navbar .buttons button:focus,
.navbar .buttons button:active,
.link-btn-default:hover,
.navbar .buttons .notificaciones:hover {
  border: 1px solid #92004D;
  color: #fff;
  background-color: #92004D;
}
.navbar .buttons button img,
.navbar .buttons button svg,
.navbar .buttons .notificaciones svg {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 7px;
  left: 8px;
}
.navbar .buttons button .icon {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.notificaciones {
  display: inline-block;
  position: relative;
  border-radius: 5px;
}

.notificaciones .notificaciones-popup {
  position: absolute;
  bottom: -6px;
  right: -6px;
  font-size: 10px;
  width: 15px;
  height: 15px;
  background-color: #33383b;
  border-radius: 50px;
  color: #fff;
}

.link-btn-default {
  padding: 0;
  width: 30px;
  height: 30px !important;
  position: relative;
  border: 1px solid #b0b0b0;
  color: gray;
  background-color: #fff;
}

/* checkbox switch*/
/* .navbar .check-menu input{
    height: 20px;
    background-color: #b0b0b0;
    border-color: #b0b0b0;
}
.navbar .check-menu input[type="checkbox"]{
    border-radius:5px;
} */
.form-switch {
  padding: 0 !important;
}
.form-switch .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.form-switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.form-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 28px;
  height: 26px;
  border-radius: 5px;
  margin-top: 5px;
}

.form-switch .slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 12px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.form-switch input:checked + .slider {
  background-color: #ccc;
  border-radius: 5px;
}

.form-switch input:focus + .slider {
  box-shadow: none;
}

.form-switch input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/* Rounded sliders */
.form-switch .slider.round {
  border-radius: 34px;
}

.form-switch .slider.round:before {
  border-radius: 50%;
}

/* SIDEBAR */

.sidebar {
  height: 100%;
  width: 50px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #33383b;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 20px;
  /* margin-left: 50px !important; */
}

.sidebar a {
    padding: 8px 8px 8px 15px;
    text-decoration: none;
    font-size: 18px;
    color: #fff !important;
    display: block;
    transition: 0.3s;
    margin: 15px 0;
    white-space: nowrap;
}
  
.sidebar a:hover, .sidebar a.active {
    color: #fff !important;
    background-color: #92004D;
    /* border-radius: 5px; */
    text-decoration: none;
}
.sidebar .header{
    color: #92004D;
    border-bottom: 1px solid #92004D;
    white-space: nowrap;
    text-align: center;
}
.sidebar .header a{
    margin: 0;
    padding: 0;
}
.sidebar .header a:hover{
    background-color: transparent;
}
.sidebar .menu-content{
    display: -webkit-box;
    height: 100%;
}
.sidebar .lista-principal{
    width: 50px; 
    background: #92004D; 
    /* position: 'absolute'; 
    z-index: 1; 
    top: 115; 
    left: 0;  */
  height: "100%";
  position: relative;
  text-align: center;
}
.sidebar .mostrar {
  opacity: 1;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
.sidebar .ocultar {
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
.sidebar .header .logo {
  opacity: 1;
  width: 100px;
  margin-bottom: 20px;
}
.sidebar .header .logomini {
  width: 30px;
  margin-bottom: 20px;
}

@keyframes mostrar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ocultar {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.sidebar .lista {
  text-align: left;
  font-size: 20px;
  width: 100%;
  /* margin-left: 2.4rem; */
}
.sidebar .lista-principal {
  font-size: 20px;
  padding: 20px 0;
}
.sidebar .lista-principal ul li {
  margin: 20px 0;
  cursor: pointer;
  opacity: 0.5;
}
.sidebar .lista-principal ul li:hover,
.sidebar .lista-principal ul li.active {
  opacity: 1;
}
.sidebar .lista-principal ul li:first-child {
  margin-top: 0;
}
.sidebar .lista-principal ul li:last-child {
  margin-bottom: 0;
}
.sidebar .lista ul,
.sidebar .lista-principal ul {
  list-style: none;
  padding: 0;
}
.sidebarIcons {
  margin-right: 10px;
}

.navbarBadge .css-jcn4dz-MuiBadge-badge{
  background-color: #92004D;
  color: #fff; 
  top: -15px; 
  right: 25px;
}