.DragNDrop {
  position: relative;
  box-sizing: border-box;
  .DragNDrop__label {
    position: absolute;
    top: -13px;
    left: 10px;
    background-color: white;
    padding: 0 1% 0 1%;
    z-index: 10000;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    cursor: default;
  }

  .DragNDrop__label--placeholder {
    position: absolute;
    top: -13px;
    left: 10px;
    background-color: transparent;
    padding: 0 1% 0 1%;
    z-index: 10000;
  }

  .DragNDrop__label--success {
    position: absolute;
    top: -13px;
    left: 10px;
    background-color: transparent;
    padding: 0 1% 0 1%;
    z-index: 10000;
  }

  .DragNDrop__label--error {
    position: absolute;
    top: -13px;
    left: 10px;
    background-color: transparent;
    padding: 0 1% 0 1%;
    z-index: 10000;
  }

  .DragNDrop__area {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    font-size: 12px;
    color: #555555;
    border: 2px #c3c3c3 dashed;
    border-radius: 4px;
    padding: 16.5px 14px;
    cursor: pointer;

    /* .area__icon {
      font-size: 64px;
      margin-top: 20px;
    } */
  }
  .DragNDrop__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 56px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    background-color: #e7e7e7;
    border-radius: 4%;
    color: #7f8e99;
    font-size: 12px;
    opacity: 0.9;
    text-align: center;
    line-height: 1.4;

    &.DragNDrop__placeholder--error {
      background-color: #f7e7e7;
      color: #cf8e99;
    }

    &.DragNDrop__placeholder--success {
      background-color: #e7f7e7;
      color: #8ecf99;
    }
  }
}
