.lae-flex {
  display: flex;

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }
  gap: 16px;
  &-between {
    justify-content: space-between;
  }
}

.pos_relative {
  position: relative;
}

.post_absolute {
  position: absolute;
}

.lae_alignItems-center {
  align-items: center;
}

.lae-justifyContent-center {
  justify-content: center;
}

.lae-justifyContent-spaceBetween {
  justify-content: space-between;
}
